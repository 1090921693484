import React from "react"
import { Menu } from "semantic-ui-react"
import { useStaticQuery, graphql } from "gatsby"
import { buildNavItem } from "../../utilities"


const MobileMenu = () => {
    const { strapiHeaderMenu } = useStaticQuery(
        graphql`
      query {
        strapiHeaderMenu(id: {ne: ""}) {
          MenuLinkGroup {
            ChildLinks {
              Title
              InternalPage{
                Url
              }
              Url
              NewTab
              IconId
            }
            ParentLink {
              Title
              InternalPage{
                Url
              }
              Url
              NewTab
              IconId
            }
          }
        }
      }
    `
    )
    const menu = strapiHeaderMenu.MenuLinkGroup.map((group, i) =>
        group.ChildLinks.length === 0 ?
            (<Menu.Item key={i} class='mobile'>
              {buildNavItem(group.ParentLink)}
            </Menu.Item>)
            :
            (
                <Menu.Item key={i} className='mobile'>
                    <Menu.Menu>
                        <Menu.Header>
                          <Menu.Item>
                            {buildNavItem(group.ParentLink)}
                          </Menu.Item>
                        </Menu.Header>

                        {group.ChildLinks.map((link, j) =>
                          <Menu.Item key={j}>
                            {buildNavItem(link)}
                          </Menu.Item>
                        )}
                    </Menu.Menu>
                </Menu.Item>
            )
    );

    return (
        <>
            {menu}
        </>
    )
}

export default MobileMenu