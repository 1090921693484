import React from "react"
import { Link } from "gatsby"
import { Button, Icon, Grid, Segment, List } from "semantic-ui-react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const { strapiFooter } = useStaticQuery(
    graphql`
      query {
        strapiFooter(id: { ne: "" }) {
          Email
          Phone
          LocationLink
          EmailLink
          CopyrightText
          FooterLinks {
            Url
            Target
            Text
          }
          CompanyName
        }
      }
    `
  )
  return (
    <>
      <div>
        <Grid stackable columns={3} padded>
          <Grid.Column>
            <Segment>
              <List>
                <List.Item icon="inbox" content={strapiFooter.Email} />
                <List.Item icon="phone" content={strapiFooter.Phone} />
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment style={{ padding: 0 }}>
              <Button as={Link} to={strapiFooter.LocationLink} icon fluid>
                <Icon name="map marker alternate" size="huge" />
              </Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment style={{ padding: 0 }}>
              <Button as={Link} to={strapiFooter.EmailLink} icon fluid>
                <Icon name="envelope" size="huge" />
              </Button>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>

      <div style={{ textAlign: "center" }}>
        <List divided horizontal>
          {strapiFooter.FooterLinks.map((link, i) => (
            <List.Item key={i} as="a" href = {link.Url} target = {link.Target}>
              {link.Text}
            </List.Item>
          ))}
        </List>
      </div>

      <div style={{ textAlign: "center" }}>
        <List divided horizontal>
          <List.Item content={`${strapiFooter.CopyrightText.replace("[year]", `${new Date().getFullYear()}`)}`} />
          <List.Item content={strapiFooter.CompanyName} />
        </List>
      </div>
    </>
  )
}

export default Footer
