import React from 'react'
import UnderConstruction from 'react-under-construction';
import 'react-under-construction/build/css/index.css';

const UnderConstructionPlaceholder = () => {
    return (
        <UnderConstruction
            background={{
            // image: 'https://static.pexels.com/photos/577585/pexels-photo-577585.jpeg',
            textColor: '#000',
            overlay: {
                color: '#fff'
            }
            }}
            logo={{
              src: 'logo-gold.png',
              alt: 'Joseph Company Logo',
              style: {'width':'350px'}
            }}
            title={{
            text: 'Joseph Company Ecuador'
            }}
            description={{
            text: 'Sitio en construcción',
            style: {
                maxWidth: '440px',
            }
            }}
            links={[
            {
                url: 'mailto:info@josephcompany.com.ec',
                image: 'https://image.flaticon.com/icons/svg/321/321817.svg',
                text: 'Contacto',
                textStyle: {'color':'#000'}
            },
            ]}
      />
    )
}

export default UnderConstructionPlaceholder