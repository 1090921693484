import React from "react"
import { Link } from "gatsby"
import { createMedia } from "@artsy/fresnel";
import { Icon } from 'semantic-ui-react'


export const getLinkFromGeneralLink = (link) => {
    if(!link) return null
    return link.InternalPage ? `/${link.InternalPage.Url}` : link.Url
 };
 
export const AppMedia = createMedia({
    breakpoints: {
      mobile: 320,
      tablet: 768,
      computer: 992,
      largeScreen: 1200,
      widescreen: 1920
    }
  });

export const buildNavItem = (link, style) => {
  if (!link) return null

  const getDisplay = () =>{
    const icon = link.IconId ? <Icon name={link.IconId}/> : null
    const title = link.Title ? link.Title : null
    return (
      <>
        {icon}
        {title}
      </>
    )
  }
  const getTarget = () => {
    return link.NewTab ? '_blank' : null
  }

  if (link.InternalPage)
  {
    return (
      <Link to={getLinkFromGeneralLink(link)} style={style} target={getTarget()}>
        {getDisplay()}
      </Link>
    )
  }
  if (link.Url)
  {
    return (
      <a href={link.Url} style={style} target={getTarget()}>
        {getDisplay()}
      </a>
    )
  }
  return getDisplay()
}