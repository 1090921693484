import React from "react"
import { Container } from "semantic-ui-react"
import Footer from "../Footer"

const Structure = ({ children }) => {
    return (
        <>
            <Container>
                {children}
            </Container>
            <Container>
                <Footer />
            </Container>
        </>
    )
}

export default Structure