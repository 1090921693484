import React, { useState } from "react"
import { Container, Button, Sidebar, Menu, Icon } from "semantic-ui-react"
import 'semantic-ui-css/semantic.min.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./index.css"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import UnderConstructionPlaceholder from "../UnderConstructionPlaceholder"
import Header from "../Header"
import { AppMedia } from "../../utilities"
import Structure from "./Structure"
import MobileMenu from "./MobileMenu"

const Layout = ({ location, children }) => {
  const { strapiMetadata } = useStaticQuery(
    graphql`
      query {
        strapiMetadata(id: { ne: "" }) {
          PageTitle
          MaintenanceMode
        }
      }
    `
  )

  const [visible, setVisible] = useState(false)
  const mediaStyles = AppMedia.createMediaStyle();
  const { Media, MediaContextProvider } = AppMedia;

  return strapiMetadata.MaintenanceMode ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{strapiMetadata.PageTitle}</title>
        <link rel="canonical" href="https://josephcompany.com.ec" />
      </Helmet>
      <UnderConstructionPlaceholder />
    </>
  ) : (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{strapiMetadata.PageTitle}</title>
          <link rel="canonical" href="https://josephcompany.com.ec" />
        </Helmet>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <Media lessThan="tablet">
            <Container>
              <Header hideNav={true} />
              <Menu fluid style={{ marginBottom: "1em" }}>
                <Menu.Item as={Button} onClick={() => setVisible(!visible)}>
                  <Icon name='bars' />
                  Menu
                </Menu.Item>
              </Menu>
            </Container>
            <Sidebar.Pushable>
              <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setVisible(false)}
                vertical
                visible={visible}
                width='thin'
              >
                <MobileMenu />
              </Sidebar>
              <Sidebar.Pusher dimmed={visible}>
                <Structure hideNav={true}>
                  {children}
                </Structure>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media greaterThanOrEqual="tablet">
            <Container>
              <Header hideNav={false} />
            </Container>
            <Structure>
              {children}
            </Structure>
          </Media>
        </MediaContextProvider>
      </>
    )
}
export default Layout
