import React from "react"
import { Image, Menu, Dropdown } from "semantic-ui-react"
import { useStaticQuery, graphql } from "gatsby"
import { buildNavItem } from "../../utilities"


const Header = ({ hideNav }) => {
  const { strapiHeaderMenu } = useStaticQuery(
    graphql`
      query {
        strapiHeaderMenu(id: {ne: ""}) {
          Logo{
            childImageSharp {
              sizes(maxWidth: 500) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          MenuLinkGroup {
            ChildLinks {
              Title
              InternalPage{
                Url
              }
              Url
              IconId
              NewTab
            }
            ParentLink {
              Title
              InternalPage{
                Url
              }
              Url
              IconId
              NewTab
            }
          }
        }
      }
    `
  )

  const linkStyle = { color: "rgba(0,0,0,.87)" };
  const menu = strapiHeaderMenu.MenuLinkGroup.map((group, i) =>
    group.ChildLinks.length === 0 ?
      (<Menu.Item key={i}>
        {buildNavItem(group.ParentLink, linkStyle)}
      </Menu.Item>)
      :
      (<Dropdown key={i} item simple trigger={buildNavItem(group.ParentLink, linkStyle)}>
        <Dropdown.Menu>
          {group.ChildLinks.map((link, j) =>
            <Dropdown.Item key={j}>
              {buildNavItem(link, linkStyle)}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>)
  );

  return (
    <>
      <Image
        src={strapiHeaderMenu.Logo?.childImageSharp?.sizes?.src}
        alt='Joseph Company'
      />
      {!hideNav &&
        <Menu fluid style={{ marginBottom: "1em" }} className='desktop' widths={strapiHeaderMenu.MenuLinkGroup.length}>
          {menu}
        </Menu>}
    </>
  )
}

export default Header
